<template>
  <div v-if="egress_profile">
    <AchievementRangeGroupSelector
      class="mt-2"
      :egress_profile_id="egress_profile.id"
      @selected_range_group="selectedRangeGroup"
    ></AchievementRangeGroupSelector>
    <AssessmentCompetenceEgrProTable
      v-if="show_assessment_range_table"
      :key="
        competencesFilter.map((x) => x.assessment).join(',') +
        selected_achievement_range_group +
        '-' +
        selected_student_status
      "
      :egress_profile="egress_profile"
      :main_assessments="competencesFilter.map((x) => x.assessment)"
      :campuses_="campuses_"
      :cohorts_="cohorts_"
      :cycles_="cycles_"
      :selected_student_status="selected_student_status"
    >
    </AssessmentCompetenceEgrProTable>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AssessmentCompetenceEgrPro",
  components: {
    AssessmentCompetenceEgrProTable: () =>
      import(
        "@/components/reports/TeachingReport/AssessmentCompetenceEgrPro/AssessmentCompetenceEgrProTable"
      ),
    AchievementRangeGroupSelector: () =>
      import(
        "@/components/reports/TeachingReport/AchievementRangeGroupSelector.vue"
      ),
  },
  props: {
    egress_profile: {
      type: Object,
      required: true,
    },
    cohorts_: {
      type: Array,
      required: true,
    },
    campuses_: {
      type: Array,
    },
    cycles_: {
      type: Array,
    },
    student_status_: {
      type: Array,
    },
  },
  data() {
    return {
      selected_achievement_range_group: null,
      show_assessment_range_table: false,
      // student_status: [
      //   { id: 1, name: "En Régimen" },
      //   { id: 2, name: "No en Régimen" },
      //   { id: 3, name: "Todos" },
      // ],
    };
  },
  computed: {
    ...mapGetters({
      profile_competences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      competences: names.COMPETENCES,
    }),
    selected_student_status() {
      const student_status_ids = this.student_status_.map((x) => x.id);
      if (student_status_ids.includes(1) && student_status_ids.includes(2))
        return 3;
      else if (student_status_ids.includes(1)) return 1;
      else if (student_status_ids.includes(2)) return 2;
      else return 3;
    },
    profileCycles() {
      return this.profile_cycles.filter(
        (x) => x.egress_profile == this.egress_profile.id
      );
    },
    profileCompetencesFilter() {
      if (!this.egress_profile) return [];
      if (this.profileCycles.length == 0)
        return this.profile_competences
          .filter((x) => x.egress_profile == this.egress_profile.id)
          .sort(function (a, b) {
            if (a.order > b.order) return 1;
            if (a.order < b.order) return -1;
            return 0;
          });
      const cycle_ids = this.cycles_.map((x) => x.id);
      let list = [];
      if (cycle_ids.length > 0)
        list = this.profile_competences.filter(
          (x) =>
            x.egress_profile == this.egress_profile.id &&
            cycle_ids.includes(x.cycle)
        );
      else
        list = this.profile_competences.filter(
          (x) => x.egress_profile == this.egress_profile.id
        );
      return list
        .map((x) => {
          let profile_cycle = this.profile_cycles.find(
            (c) => c.cycle == x.cycle
          );
          return {
            ...x,
            profile_cycle_order: profile_cycle ? profile_cycle.order : 0,
          };
        })
        .sort(function (a, b) {
          if (a.profile_cycle_order > b.profile_cycle_order) return 1;
          if (a.profile_cycle_order < b.profile_cycle_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    competencesFilter() {
      return this.profileCompetencesFilter.map((x) => {
        const competence = this.competences.find((c) => c.id == x.competence);
        if (competence) return { ...competence };
      });
    },
  },
  methods: {
    selectedRangeGroup(achievement_range_group_id) {
      this.selected_achievement_range_group = achievement_range_group_id;
      this.show_assessment_range_table = true;
    },
  },
  created() {
    if (this.egress_profile) {
      this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
        egress_profile_id: this.egress_profile.id,
      });
      this.$store.dispatch(names.FETCH_COMPETENCES, this.egress_profile.id);
    }
  },
};
</script>

<style scoped>
</style>

